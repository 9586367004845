import React, { useRef, useState } from 'react'
import LoginHeading from "../../assets/login card/heading.png"
import Logo from "../../assets/login card/bhagwanlogo.png"
import LanginPage from "../../assets/landingpage/landingpage.png"
import { Link, useNavigate } from 'react-router-dom'
import welcome from "../../assets/login card/ladyphoto.png"
import mobileText from "../../assets/login card/text.png"
import otpText from "../../assets/login card/otppagetext.png"
import newReistration from "../../assets/login card/newregistrationbutton.png"
import companyLogo from "../../assets/login card/poweredbylogo.png"
import { useDispatch, } from 'react-redux'
import { requestOTP, verifyOTP } from '../../Redux/authSlicer'
import memberRegistrationImg from "../../assets/landingpage/memberRegistrationImg.png"
import matrimonyRegistrationImg from "../../assets/landingpage/matrimonyRegistrationImg.png"
import { navigation } from '../Route'

export default function LandingPage() {

    const [isOpen, setIsOpen] = useState(false);
    const [isRegisterOptionOpen, setRegisterOptionOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} toggleModal={toggleModal} />
            <OpenForms isOpen={isRegisterOptionOpen} toggleModal={setRegisterOptionOpen} />
            <div className=' mt-3 '>
                <div className=' relative flex justify-center'>
                    {/* <h1 className=' text-2xl text-red-500 font-bold'> वैश्य</h1> */}
                    <img className=' w-80' src={LoginHeading} alt='heading' loading='lazy' />
                    <img className=' absolute top-[2rem] w-[140px]' src={Logo} />
                </div>
                <div className='h-full pt-24    sm:h-[100%]   flex flex-col justify-center items-center mt-20  landing_page_gradian'>
                    <img className=' w-80 sm:w-[65%]  xl:w-[20%] ' src={LanginPage} loading='lazy' alt='landing' />
                    <div className='w-full flex justify-around my-2'>
                        {/* <Link to={'/first/registration'} className=' px-3 p-1 flex justify-center items-center text-center text-white font-bold bg-[#3E4095] rounded-2xl'>
                            नया रजिस्ट्रेशन करे
                        </Link> */}
                        <div onClick={() => setRegisterOptionOpen(true)} className=' px-3 p-1 cursor-pointer flex justify-center items-center text-center text-white font-bold bg-[#3E4095] rounded-2xl'>
                            नया रजिस्ट्रेशन करे
                        </div>


                        <Link onClick={toggleModal} className=' px-3 p-1 flex justify-center items-center text-center text-white font-bold bg-[#3E4095] rounded-2xl'>
                            लॉगिन करे
                        </Link>
                    </div>

                </div>
            </div>
        </>
    )
}


function Modal({ isOpen, setIsOpen, toggleModal, }) {
    const [mobileNumber, setMobileNumber] = useState(false);
    const [isOtpOpen, setIsOtpOpen] = useState(false);
    const [isMobileNumInputValid, setMobileNumInputValid] = useState(false);
    const [isOtpCurrect, setOtpCurrect] = useState(false);
    const [otp, setOtp] = useState([0, 0, 0, 0])
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { loading, error } = useSelector((state) => state.auth);

    const mobileNumberVerify =
        async (mobileNumber) => {
            try {
                setLoading(true)
                const response = await dispatch(requestOTP({ mobileNumber }));

                if (response.error) {
                    // Handle API request error gracefully (e.g., display an error message)
                    console.error("API request error:", response.error);

                    setMobileNumInputValid("Error: " + response.error.message);
                    return // Inform user of specific error
                } else if (!response?.payload?.success) {
                    // Handle unsuccessful OTP request (e.g., mobile number not found)
                    console.warn("OTP request unsuccessful:", response?.payload?.message || "Unknown error"); // Log details if available
                    setMobileNumInputValid(response.payload?.message || "Unknown error"); // Provide user-friendly message
                } else {
                    // Successful OTP request
                    setIsOtpOpen(true);
                    setOtp([0, 0, 0, 0]); // Reset OTP input for user (optional)
                }
            } catch (error) {
                // Handle unexpected errors during dispatch (e.g., network issues)
                console.error("Unexpected error during OTP request:", error);
                setMobileNumInputValid("An unexpected error occurred. Please try again later.");
            } finally {
                setLoading(false)
            }
        };

    const checkOtpIsValid = async () => {
        try {
            setLoading(true)
            const otpStr = otp.join('');
            const response = await dispatch(verifyOTP({ otpStr, mobileNumber }));
            if (response.error) {
                console.error("API request error:", response.error.message);
                setOtpCurrect("Error: " + response.error.message);
                return;
            }

            if (!response.payload?.success) {
                console.warn("OTP request unsuccessful:", response.payload?.message || "Unknown error");
                setOtpCurrect(response.payload?.message || "Unknown error");
                return;
            }
            // OTP verified successfully, navigate to dashboard
            navigation(response.payload?.data?.userType, navigate)
            // navigate('/dashboard');
        } catch (error) {
            console.error("Failed to verify OTP:", error);
            alert("An error occurred. Please try again later.");
        } finally {
            setLoading(false)
        }
    };

    const handleOtpSubmit = async () => {
        await checkOtpIsValid();
    };
    // Example usage:
    const handleMobileNumberSubmit = async () => {
        if (/^\d{10}$/.test(mobileNumber)) {
            await mobileNumberVerify(mobileNumber);
        }
    };

    const onChangeHandlerMobile = (e) => {
        const mobileNum = e.target.value.trim(); // Trim whitespace from the input

        if (mobileNum.length > 10) {
            return
        }

        // Update state with the trimmed mobile number if it has 10 or fewer characters
        if (mobileNum.length <= 10) {
            setMobileNumber(mobileNum);
        }

        // Validate the mobile number format
        if (/^\d{10}$/.test(mobileNum)) {
            // If the mobile number is valid, set the validation message to an empty string
            setMobileNumInputValid('');
        } else if (mobileNum.length > 0) {
            // If the mobile number is invalid and has some input, set the validation message accordingly
            setMobileNumInputValid('Mobile number should be 10 digits');
        } else {
            // If there is no input, set the validation message to an empty string
            setMobileNumInputValid('');
        }
    };

    // Create refs for all input fields
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    // Function to handle input change and focus on next input
    const handleChange = (index, e) => {
        const input = e.target;
        const maxLength = parseInt(input.getAttribute('maxlength'), 4);
        const nextIndex = index + 1;
        // Move focus to the next input if current input is filled
        if (input.value.length >= maxLength && nextIndex < inputRefs.length) {
            inputRefs[nextIndex].current.focus();
        }
        // Update the values array with the value of the current input
        setOtp(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = input.value;
            return updatedValues;
        });

    };
    const onResendOtp = async () => {
        await handleMobileNumberSubmit()
        setOtp([0, 0, 0, 0])
    }
    return (
        <>
            {/* Modal toggle */}
            {/* Main modal */}
            {/* <TtpModal isOpen={isOtpOpen} setIsOpen={setIsOtpOpen} toggleModal={toggleOtpModal} /> */}

            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed   w-[100vw] h-[100vh]   top-0 right-0 left-0 z-50 flex justify-center items-center  bg-gray-900 bg-opacity-50"
                >
                    <div className="relative   flex  flex-col items-center bg-white w-[100vw] h-[100vh] md:h-[70vh] xl:h-[100vh]  sm:w-[400px] sm:h-100  ">
                        {/* Modal content */}
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 mt-3  bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* <h1 className=' text-2xl text-red-600 font-bold'> वैश्य</h1> */}
                        <img className='w-[90%] md:w-[20rem]' src={LoginHeading} />
                        <img className=' absolute top-16 w-[50%] md:w-[10rem]' src={Logo} />
                        <div
                            className='login-card-bg w-full mt-[7.5rem] flex flex-col items-center shadow-inner'
                        >
                            <div className='flex flex-col items-center mt-28'>
                                <img className=' w-16' src={welcome} />
                                <div className=' '>
                                    <label htmlFor="login_mob" className="block  text-gray-700 dark:text-gray-300">
                                        <img className=' w-64 ' src={isOtpOpen ? otpText : mobileText} />
                                    </label>
                                    {isOtpOpen ?
                                        <>
                                            <div className="flex space-x-2 shadow-2xl">
                                                {
                                                    inputRefs.map((ref, index) => (
                                                        <input
                                                            key={index}
                                                            ref={ref}
                                                            id={`otp${index + 1}`}
                                                            type="tel"
                                                            placeholder=""
                                                            maxLength="1"
                                                            className="flex-1  w-[3rem] h-[3rem] px-4 py-2 text-2xl text-center text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-200 dark:placeholder-gray-400 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-gray-500 dark:focus:border-gray-500"
                                                            onChange={(e) => handleChange(index, e)}
                                                            autoFocus={index === 0}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            {isOtpCurrect && <p id="username-error" class="text-red-500 text-xs mt-1 inline-block">{isOtpCurrect}</p>}
                                            <span onClick={onResendOtp} className='  font-bold text-blue-600 btn text-xs text-end'>Resend</span>
                                        </>
                                        : <> <div className='shadow-2xl'>
                                            <input
                                                value={mobileNumber}
                                                onChange={(e) => onChangeHandlerMobile(e)}
                                                id="login_mob"
                                                type="number"

                                                autoFocus={true}
                                                placeholder="mobile number"
                                                className="block w-full  px-4  py-2 mt-1 text-sm text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-200 dark:placeholder-gray-400 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-gray-500 dark:focus:border-gray-500"
                                            />

                                        </div>
                                            {isMobileNumInputValid && <p id="username-error" class="text-red-500 text-xs mt-1 ">{isMobileNumInputValid}</p>}

                                        </>
                                    }
                                </div>
                                <div className=' my-4 '>
                                    {
                                        !isOtpOpen ? (

                                            <span className=' '>
                                                {loading ? <div className=" w-10 h-10 border-t-4 border-b-4 border-[#3E4095] rounded-full animate-spin"></div> : <div onClick={handleMobileNumberSubmit} className='rounded-md cursor-pointer  bg-[#3E4095] px-4 py-1 text-yellow-300'>ओटीपी पाएं</div>}
                                            </span>
                                        ) : (
                                            <span className=' '>
                                                {loading ? <div className=" w-10 h-10 border-t-4 border-b-4 border-[#3E4095] rounded-full animate-spin"></div> : <div onClick={handleOtpSubmit} className='rounded-md cursor-pointer  bg-[#3E4095] px-4 py-1 text-yellow-300'>लॉगिन करे </div>}
                                            </span>


                                        )
                                    }

                                </div>

                                <Link to='/first/registration' onClick={() => toggleModal()} className=' mt-10 mb-4'>
                                    <img className=' w-60 cursor-pointer' src={newReistration} />
                                </Link>
                            </div>

                        </div>
                        <div className=''>
                            <img className=' w-60 cursor-pointer' src={companyLogo} />
                        </div>

                    </div>
                </div>
            )
            }
        </>
    );
}

const OpenForms = ({ isOpen, toggleModal }) => {

    return (
        <>
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 px-2 flex justify-center items-center z-50 bg-gray-900 bg-opacity-80"
                >
                    <div className="relative flex flex-col items-center bg-white  max-w-sm p-6 rounded-lg">
                        {/* Modal content */}
                        <button
                            onClick={() => toggleModal(false)}
                            type="button"
                            className="absolute top-0  right-0  p-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm inline-flex justify-center items-center"
                        >
                            <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Add your ad content here */}
                        <div className=' flex flex-col gap-y-2 items-center '>
                            {/* ring-2 ring-yellow-400 rounded-full */}
                            <Link to={'/first/registration'} className='bg-blue-500 text-white transition-colors flex items-center  py-4 px-5 w-[300px] h-[100%] border-2 border-blue-500 rounded-md '>
                                <img className=' w-14 h-14 me-5' src={memberRegistrationImg} />
                                <div>
                                    <h1 className=' font-bold text-[20px]'>समाज मेंबर </h1>
                                    <small>समाज मैं मेंबर बने </small>
                                </div>
                            </Link>
                            <Link to={'/matrimony/registration'} className='bg-yellow-500  transition-colors flex items-center py-4 px-5 w-[300px] h-[100%] border-2 border-blue-500 rounded-md '>
                                <img className=' w-14 h-14 me-5' src={matrimonyRegistrationImg} />
                                <div>
                                    <h1 className=' font-bold text-[20px]'>विवाह रिश्ते </h1>
                                    <small>विवाह रिश्ते  </small>
                                </div>
                            </Link>
                        </div>
                        {/* <img className=" w-52" src={mandirLogo} /> */}
                    </div>
                </div>
            )}
        </>
    );
};




{/* <img
                                                onClick={handleOtpSubmit}
                                                className="w-28 cursor-pointer"
                                                src={otpLoginBtn}
                                                alt="OTP Login Button"
                                            /> */}
{/* <HeroSection />
            <FeaturesSection />
            <TestimonialsSection /> */}
const HeroSection = () => {
    return (
        <section className="bg-gray-100 py-20">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold mb-4">Welcome to Social Samaj</h2>
                <p className="text-lg text-gray-600">Connect with like-minded individuals and make a difference in your community.</p>
                <button className="mt-8 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600">Join Now</button>
            </div>
        </section>
    );
}



const FeaturesSection = () => {
    return (
        <section id="features" className="py-20">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold mb-8">Our Features</h2>
                {/* Add feature cards or descriptions */}
            </div>
        </section>
    );
}




const TestimonialsSection = () => {
    return (
        <section id="testimonials" className="bg-gray-100 py-20">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold mb-8">Testimonials</h2>
                {/* Add testimonials */}
            </div>
        </section>
    );
}


