import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { HeaderBoarder } from '../Components'
import { CheackBox, CityDropDown, Input, PhotoUpload, SearchDropDownCriteria, SelectInput, Textarea } from '../FormsInputes.js';
import { useDispatch, useSelector } from 'react-redux';
import FormHeading from './FormHeading.js';
import { addMatrimonyProfile, genarateMatrimonySharebleQr, isMobileNumberRegisterd, upDateMember } from '../service/Services.js';
import { Link } from 'react-router-dom';
import Logo from "../assets/login card/bhagwanlogo.png"
import Swal from 'sweetalert2';
import { showSuccessAlert } from '../Components/ShowAlertCustome.js';
import { gotra } from "../constants/staticData.js"
import { Domain, clientDomain } from '../ApiConfig.js';
import { faLinesLeaning } from '@fortawesome/free-solid-svg-icons';
import LoadingModal from './LoadingModel.js';
import { ShowQrCode } from '../Components/PopupModel.js';
import { updateMember } from '../Redux/authSlicer.js';
import { occupations } from "../constants/staticData.js"
import AvatarEditorCom from './AvatarEditor.js';
import { TermsAndConditions } from './registrationForm.js';
// import Calendar from 'react-calendar';


const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
const skinColor = ['fair', 'dark', 'medium']
const motherTongue = ['Hindi', 'English', 'Marathi']

const rashis = [
    "मेष",   // Aries (मेष)
    "वृष",   // Taurus (वृषभ)
    "मिथुन", // Gemini (मिथुन)
    "कर्क",   // Cancer (कर्कट)
    "सिंह",   // Leo (सिंह)
    "कन्या",  // Virgo (कन्या)
    "तुला",   // Libra (तुला)
    "वृश्चिक", // Scorpio (वृश्चिक)
    "धनु",    // Sagittarius (धनु)
    "मकर",    // Capricorn (मकर)
    "कुंभ",   // Aquarius (कुंभ)
    "मीन"     // Pisces (मीन)
]


const relations = ["Wife", "Mother", "Father", "Son",
    "Daughter",
    "Brother", "Sister",
    "Brother's Wife",
    "Grandmother",
    "Grandfather", "Other"]

const currentMarritalStatus = [
    'Unmarried', 'Remarriage', 'Widow', 'Single', 'Divorcee'
]

const initialFormData = {
    personalInformation: {
        name: "",
        surname: "",
        gotra: "",
        skinColor: "",
        motherTongue: "",
        languagesKnown: "",
        weightInKG: "",
        heightFeet: "",
        heightInch: "",
        bloodGroup: "",
        gender: "",
        currentMaritalStatus: "",
        permanentAddress: "",
        permanentCity: "",

        permanentPincode: "",
        currentAddress: "",
        currentCity: "",

        currentPincode: "",
    },
    divorceDetails: {
        divorceDate: "",
        divorceReason: "",
        childrenDetails: [{ Children: "", ChildrenAge: 0 },
        { Children: "", ChildrenAge: 0 },
        { Children: "", ChildrenAge: 0 }]
    },

    kundliDetails: {
        birthDateTime: "",
        birthPlace: "",
        rashiByDateOfBirth: "",
        rashi: "",
        mangalik: "",
    },

    occupationDetails: {
        occupation: "",
        companyName: "",
        designation: "",
        occupationCity: "",
        occupationState: "",
        occupationCountry: "",
        averageSalaryPerMonth: "",
    },

    contactDetails: {
        mobileNo: "",
        whatsappNo: "",
        facebookId: "",
        linkedInId: "",
        instagramId: "",
        emailId: "",
    },

    familyDetail: {
        fatherName: "",
        fatherOccupation: "",
        fatherMobileNo: "",

        motherName: "",
        motherOccupation: "",
        motherMobileNo: "",

        brother: "",
        marriedBrother: "",
        unmarriedBrother: "",
        sisters: "",
        marriedSister: "",
        unmarriedSister: "",
        residingWithFamily: "",
        currentFamilyType: "",
        interestedInFamilyType: "",
    },
    mamaDetails: {
        mamaName: "",
        mamaOccupation: "",
        mamaMobileNo: "",
        mamaCity: "",
        mamaState: "",
        mamaCountry: "",
        mamaGotra: "",

    },
    othersDetails: {
        achievementsAwards: "",
        expectationFromRelation: "",
        hobbies: "",
    },
    samajReferenceDetails: {
        reference1Name: "",
        reference1City: "",
        reference1State: "",
        reference1Country: "",
        reference1Occupation: "",
        reference1MobileNo: "",
        reference1Relation: "",
        reference2Name: "",
        reference2City: "",
        reference2State: "",
        reference2Country: "",
        reference2Occupation: "",
        reference2MobileNo: "",
        reference2Relation: "",
    },

    extraInformation: {
        anyHealthIssues: "",
        specialQualities: "",
    },
    remarksNotes: "",
    educationQualification: ""
}

export default function MatrimonyRegistration({ adminEditData }) {
    // const user = useSelector(state => state?.auth?.user);
    // const authData = useSelector(state => state.auth);
    // const formData = authData?.user ? authData?.user : initialFormData;
    const authData = useSelector(state => state.auth);
    const formData = adminEditData ? adminEditData : authData?.user ? authData?.user : initialFormData;
    // const useformHookdata = adminEditData ? adminEditData : initialFormData;
    const { register, handleSubmit, formState: { errors }, setError, setValue, getValues } = useForm({ ...formData });
    console.log("matrimonyFoemdata", formData)
    const [state, setState] = useState([])
    const [country, setCountry] = useState([])
    const [city, setCity] = useState([])
    const [age, setAge] = useState("")
    const [maritalStatus, setSaritalStatus] = useState(formData?.personalInformation?.currentMaritalStatus || "")
    const [qr, setQr] = useState("")
    const [value, setValueLocation] = useState([]);
    const [reCapcha, setreCapchToken] = useState("");
    const [citis, setCitis] = useState({});
    const [cityOptions, setCityOptions] = useState([]);
    const [isLoading, setIsloading] = useState(false)
    const [termAndConditions, setTermAndConditions] = useState(authData.isLogin)
    const [isOpen, setIsOpen] = useState(false);
    const [isApiCall, setIsApiCall] = useState(false)
    const [permanentCity, setPermanentCity] = useState(formData?.personalInformation?.permanentCity);
    // const [currentCityOptions, setcurrentCityOptions] = useState([]);
    const [currentCity, setCurrentCity] = useState(formData?.personalInformation?.currentCity);
    // const [occupationCity, setOccupationCity] = useState(formData?.occupationDetails?.occupationCity);
    const [mamaCity, setMamaCity] = useState(formData?.mamaDetails?.mamaCity);
    const [reference1City, setReference1City] = useState(formData?.samajReferenceDetails?.reference1City);
    const [reference2City, setReference2City] = useState(formData?.samajReferenceDetails?.reference2City);
    const dispatch = useDispatch()
    const [avatar, setEditedAvatar] = useState("")
    const [isMoblieNumberAlreadyExist, setsIMoblieNumberAlreadyExist] = useState(false)
    const [mobileNumberCheckLoading, setMobileNumberCheckLoading] = useState(false)

    function calculateAgeFromDateTime(e, preSelectedDate) {
        const dateTimeString = e.target.value;
        console.log("DateTimeString:", dateTimeString);

        const today = new Date();
        console.log("Today:", today);

        // Parse the input datetime string
        const dob = new Date(dateTimeString);
        console.log("DOB:", dob);

        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        // Calculate the additional fraction of the year
        let additionalMonths = monthDiff < 0 ? 12 + monthDiff : monthDiff;
        let additionalDays = dayDiff < 0 ? new Date(today.getFullYear(), today.getMonth(), 0).getDate() + dayDiff : dayDiff;

        let fractionalYear = additionalMonths / 12;
        fractionalYear += additionalDays / 365; // Approximate by assuming 365 days in a year

        age += fractionalYear;
        console.log("Age:", age);
        setValue('birthDateTime', dateTimeString);
        // setValue('age', age.toFixed(1)); // Keep two decimal places for clarity
        setAge(age.toFixed(1) + " Years");
    }

    useEffect(() => {

        function calculateAgeFromDateTime(preSelectedDate) {
            const dateTimeString = preSelectedDate;
            console.log("DateTimeString:", dateTimeString);

            const today = new Date();
            console.log("Today:", today);

            // Parse the input datetime string
            const dob = new Date(dateTimeString);
            console.log("DOB:", dob);

            let age = today.getFullYear() - dob.getFullYear();
            const monthDiff = today.getMonth() - dob.getMonth();
            const dayDiff = today.getDate() - dob.getDate();

            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }

            // Calculate the additional fraction of the year
            let additionalMonths = monthDiff < 0 ? 12 + monthDiff : monthDiff;
            let additionalDays = dayDiff < 0 ? new Date(today.getFullYear(), today.getMonth(), 0).getDate() + dayDiff : dayDiff;

            let fractionalYear = additionalMonths / 12;
            fractionalYear += additionalDays / 365; // Approximate by assuming 365 days in a year

            age += fractionalYear;
            console.log("Age:", age);
            // setValue('birthDateTime', dateTimeString);
            // setValue('age', age.toFixed(1)); // Keep two decimal places for clarity
            setAge(age.toFixed(1) + " Years");
        }
        if (formData?.kundliDetails?.birthDateTime) {
            calculateAgeFromDateTime(formData?.kundliDetails?.birthDateTime)
        }
    }, [])

    const handleChange = (fieldName, e) => {
        let value = e.target.value
        const mobileMuners = ['whatsappNo', 'mobileNo', 'fatherMobileNo', 'motherMobileNo', 'mamaMobileNo', 'reference1MobileNo', 'reference2MobileNo']
        if (mobileMuners.includes(fieldName)) {
            // Remove non-numeric characters
            value = value.replace(/\D/g, '');
            if (value.length > 10) {
                value = value.slice(0, 10); // Limit the value to 10 digits
            }
            setValue(fieldName, value, { shouldValidate: true });
        } else {
            setValue(fieldName, value, { shouldValidate: true });
        }
    };

    const handleFileChange = (fileName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fileName, file);
    };
    const handleMarritalStatus = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true });
        setSaritalStatus(value)
    };

    const handelTermsAndConditions = (e) => {
        if (e.target.checked) {
            setIsOpen(true)
            setTermAndConditions(true)
        } else {
            setIsOpen(false)
            setTermAndConditions(false)
        }

        // Toggle the checked state
        // setIsChecked(!termAndConditions);
        // Call the togglePopup function
    };
    // addMatrimonyProfile
    const handleFromSubmit = async (data) => {
        if (!termAndConditions) {
            Swal.fire({
                title: 'Please read terms and conditions',
                text: 'Please read terms and conditions',
                icon: 'warning',
            });
            return;
        }
        try {
            setIsApiCall(true)

            console.log(data)
            const formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key])
            }

            const entryInSamaj = {
                curentSamaj: "Indor porwal samaj",
                id: 1
            }
            formData.append('entrySamajId', JSON.stringify(entryInSamaj));
            formData.append('permanentCity', JSON.stringify(permanentCity))
            formData.append('currentCity', JSON.stringify(currentCity))
            // formData.append('occupationCity', JSON.stringify(occupationCity))
            formData.append('profilePhoto', avatar)
            // formData.append('mamaCity', JSON.stringify(mamaCity))
            // formData.append('reference1City', JSON.stringify(reference1City))
            // formData.append('reference2City', JSON.stringify(reference2City))

            const response = await addMatrimonyProfile(formData)
            console.log('response', response)
            if (response?.success) {
                showSuccessAlert(response.message, Logo)
            } else
                if (response.error) {
                    Swal.fire({
                        title: response.error,
                        text: response.error,
                        icon: 'error',
                    });
                }

        } catch (error) {
            console.log(error)
            Swal.fire({
                title: error,
                text: error,
                icon: 'error',
            });
            setIsApiCall(false)
        } finally {
            setIsApiCall(false)
        }
    }
    const handleFromEditSubmit = async (data) => {
        setIsApiCall(true)
        try {
            console.log(data)
            const formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key])
            }
            const entryInSamaj = {
                curentSamaj: "Indor porwal samaj",
                id: 1
            }
            formData.append('entrySamajId', JSON.stringify(entryInSamaj));
            formData.append('permanentCity', JSON.stringify(permanentCity))
            formData.append('currentCity', JSON.stringify(currentCity))
            // formData.append('occupationCity', JSON.stringify(occupationCity))
            formData.append('profilePhoto', avatar)
            // formData.append('mamaCity', JSON.stringify(mamaCity))
            // formData.append('reference1City', JSON.stringify(reference1City))
            // formData.append('reference2City', JSON.stringify(reference2City))
            // const response = await dispatch(updateMember({ uploadForm: formData, userType: 4 }))
            let response
            if (adminEditData) {
                const headers = {
                    'Content-Type': 'multipart/form-data',
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                };
                formData.append('membershipID', adminEditData?.membershipID);
                // console.log('adminEditData', adminEditData)
                response = await upDateMember(formData, 4, requestOptions)
                console.log("response", response)
            } else {
                response = await dispatch(updateMember({ uploadForm: formData, userType: 4 }))
            }
            console.log(response)
            if (response?.payload?.success) {
                showSuccessAlert(response?.payload?.message, Logo)
            } else if (response?.success) {
                showSuccessAlert(response?.message, Logo)
            } else if (response.error) {
                Swal.fire({
                    title: response.error,
                    text: response.error,
                    icon: 'error',
                });
            }
        } catch (error) {
            // Handle error
            console.error('Error updating member:', error);
            Swal.fire({
                title: error.response?.data.message,
                text: error.response?.data.message,
                icon: 'error',
            });
            setIsApiCall(false)
        } finally {
            setIsApiCall(false)
        }
    }
    const onGerateQrCode = async () => {
        setIsloading(true)
        try {
            const qr = await genarateMatrimonySharebleQr({ domain: `${clientDomain}QR` })
            console.log(qr?.data?.qrCodeUrl)
            setQr(qr?.data?.qrCodeUrl)
        } catch (error) {

        } finally {
            // setIsloading(false)
        }
    }

    const handleRegisterMobileNoChange = async (e) => {
        let value = e.target.value;
        setMobileNumberCheckLoading(false);
        setsIMoblieNumberAlreadyExist(false);

        // Remove non-numeric characters
        value = value.replace(/\D/g, '');

        if (value.length > 10) {
            value = value.slice(0, 10); // Limit the value to 10 digits
        }

        setValue("mobileNo", value, { shouldValidate: true });

        if (value.length === 10) {
            setsIMoblieNumberAlreadyExist(true);
            await mobileNumberCheckHandler(value);
        }
    };

    const mobileNumberCheckHandler = async (number) => {

        try {

            const responce = await isMobileNumberRegisterd({ register_MobileNumber: number })
            console.log("responce", responce);

            if (responce?.success) {
                setMobileNumberCheckLoading(true)
            }
        } catch (error) {
            console.log(error)
            setError('mobileNo', {
                type: 'manual',
                message: 'Mobile Number Already Exists',
            });
            // setMobileNumberCheckLoading(true)
        } finally {
            setsIMoblieNumberAlreadyExist(false)
        }

    }


    return (
        <>
            <LoadingModal show={isApiCall} text={'updating...'} />
            {!(authData?.isLogin) && <HeaderBoarder heading={"Matrimony"} />}
            <TermsAndConditions isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* <div className=' w-52'><Calendar /></div> */}
            <ShowQrCode isOpen={isLoading} setIsOpen={setIsloading} img={qr} />

            <div className='w-100  md:px-10 bg-yellow-500 '>
                {authData?.isLogin && <div className=' w-full flex justify-end my-2'>
                    <button onClick={onGerateQrCode} className='btn bg-black text-white font-bold'>Generate QR</button>
                </div>}
                <form onSubmit={handleSubmit(authData.isLogin ? handleFromEditSubmit : handleFromSubmit)} className='border-[1px] border-gray-900/10 p-4'>
                    <div className="space-y-12 border-[1px]  border-gray-900/10 ">
                        <div className=" border-gray-900/10 pb-2">
                            <FormHeading heading={'Personal Information / व्यक्तिगत जानकारी'} />
                            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-2 md:gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                                {/* First Name */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="First Name / पहला नाम"
                                        placeholder="First Name"
                                        type="text"
                                        required="required"
                                        {...register("name", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.name}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("name", e)}
                                    />
                                    {errors.firstName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.firstName.message}</p>}
                                </div>
                                {/* surName */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Surname / उपनाम"
                                        placeholder="Surname"
                                        type="text"
                                        required="required"
                                        {...register("surname", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.surname}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "कृपया 20 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("surname", e)}
                                    />
                                    {errors.surname && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.surname.message}</p>}
                                </div>

                                {/* gotra */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Gotra / गोत्र"
                                        placeholder="Gotra"
                                        options={gotra}
                                        required="required"
                                        {...register("gotra", {
                                            value: `${formData?.personalInformation?.gotra}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("gotra", e)}
                                    />
                                    {errors.gotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gotra.message}</p>}
                                </div>

                                {/* skin color */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Skin Color / त्वचा का कलर "
                                        placeholder="Skin Color"
                                        options={skinColor}
                                        {...register("skinColor", {
                                            value: `${formData?.personalInformation?.skinColor}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("skinColor", e)}
                                    />
                                    {errors.skinColor && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.skinColor.message}</p>}
                                </div>

                                {/* motherTongue */}
                                {/* <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Mother Tongue / मात्रभाषा"
                                        placeholder="Skin Color"
                                        options={motherTongue}
                                        required="required"
                                        {...register("motherTongue", {
                                            // value: `${formData?.gotra}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("motherTongue", e)}
                                    />
                                    {errors.motherTongue && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.motherTongue.message}</p>}
                                </div> */}

                                {/* Languages known */}
                                {/* <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Languages known / ज्ञात भाषाएँ"
                                        placeholder="Languages known"
                                        options={motherTongue}
                                        required="required"
                                        {...register("languagesKnown", {
                                            // value: `${formData?.gotra}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("languagesKnown", e)}
                                    />
                                    {errors.languagesKnown && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.languagesKnown.message}</p>}
                                </div> */}

                                {/* weight */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="weight in KG / वजन"
                                        placeholder="weight in KG"
                                        type="text"
                                        required="required"
                                        {...register("weightInKG", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.weightInKG}`,
                                            minLength: {
                                                value: 2,
                                                message: "कृपया कम से कम 2 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 3,
                                                message: "कृपया 3 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^\d{1,3}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य  नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("weightInKG", e)}
                                    />
                                    {errors.weightInKG && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.weightInKG.message}</p>}
                                </div>

                                {/* height in feet */}
                                <div className="sm:col-span-3 h-[100%]  flex items-baseline ">
                                    <div className=" me-5">
                                        <Input
                                            label="height Feet / लम्बाई फ़ीट"
                                            placeholder="Feet"
                                            type="text"
                                            required="required"
                                            {...register("heightFeet", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.personalInformation?.heightFeet}`,
                                                minLength: {
                                                    value: 1,
                                                    message: "कृपया कम से कम 1 अंक भरें"
                                                },
                                                maxLength: {
                                                    value: 2,
                                                    message: "कृपया 2 अंक से अधिक न भरें"
                                                },
                                                pattern: {
                                                    value: /^\d{1,2}$/,
                                                    message: "कृपया मान्य  नंबर दर्ज करें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("heightFeet", e)}
                                        />
                                        {errors.heightFeet && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.heightFeet.message}</p>}
                                    </div>

                                    {/* heightInch */}
                                    <div className="">
                                        <Input
                                            label="height Inch / लम्बाई इंच"
                                            placeholder="Inch"
                                            type="text"
                                            required="required"
                                            {...register("heightInch", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.personalInformation?.heightInch}`,
                                                minLength: {
                                                    value: 1,
                                                    message: "कृपया कम से कम 1 अंक भरें"
                                                },
                                                maxLength: {
                                                    value: 2,
                                                    message: "कृपया 2 अंक से अधिक न भरें"
                                                },
                                                pattern: {
                                                    value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                    message: "कृपया मान्य संख्या दर्ज करें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("heightInch", e)}
                                        />
                                        {errors.heightInch && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.heightInch.message}</p>}
                                    </div>

                                </div>
                                {/* bloodGroup */}
                                {/* <div className="sm:col-span-3">
                                    <SelectInput
                                        label=" Blood Group / ब्लड ग्रुप "
                                        options={bloodGroups}
                                        required="required"
                                        {...register("bloodGroup", {
                                            value: `${formData?.personalInformation?.bloodGroup}`,
                                        }

                                        )}
                                        onChange={(e) => handleChange("bloodGroup", e)}
                                    />
                                    {errors.bloodGroup && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.bloodGroup.message}</p>}
                                </div> */}



                                {/* Gender */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Gender / लिंग"
                                        placeholder="Gender"
                                        options={["Male", "Female"]}
                                        required="required"
                                        {...register("gender", {
                                            value: `${formData?.personalInformation?.gender}`,
                                        })}
                                        onChange={(e) => handleChange("gender", e)}
                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                    />
                                    {errors.gender && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gender.message}</p>}
                                </div>


                                {/* current MaritalStaus */}
                                <div className="sm:col-span-3 ">
                                    <SelectInput
                                        label="Current Marital Staus / वैवाहिक स्थिति"
                                        placeholder="Current Marital Staus"
                                        options={currentMarritalStatus}
                                        required="required"
                                        {...register("currentMaritalStatus", {
                                            value: `${formData?.personalInformation?.currentMaritalStatus}`,
                                        }
                                        )}
                                        onChange={(e) => handleMarritalStatus("maritalStatus", e)}
                                    />
                                    {errors.currentMaritalStatus && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentMaritalStatus.message}</p>}
                                </div>
                                {/* ***************************************************************** */}

                                {maritalStatus === "Divorcee" && <span>

                                    <div className="sm:col-span-3">
                                        <Input
                                            label="divorce date / तलाक की तारीख"
                                            placeholder="divorce date"
                                            type="date"
                                            required="required"
                                            onChange={(e) => handleChange(e)}
                                            {...register("divorceDate", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData.divorceDetails?.divorceDate?.split('T')[0]}`,
                                            })}
                                        />
                                        {errors.divorceDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.divorceDate.message}</p>}
                                    </div>

                                    {/* divorce reason */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="divorce reason / तलाक का कारण"
                                            placeholder="divorce reason"
                                            type="text"
                                            required="required"

                                            {...register("divorceReason", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.divorceDetails?.divorceReason}`,
                                                minLength: {
                                                    value: 3,
                                                    message: "कृपया कम से कम 3 अक्षर भरें"
                                                },
                                                maxLength: {
                                                    value: 40,
                                                    message: "कृपया 40 अक्षरों से कम भरें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("divorceReason", e)}
                                        />
                                        {errors.divorceReason && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.divorceReason.message}</p>}
                                    </div>

                                    {/* Children1 */}
                                    <h1 className=' col-span-full text-xl font-semibold '>Children details / बच्चों का विवरण</h1>

                                    {formData?.divorceDetails?.childrenDetails?.length != 0 ?
                                        formData?.divorceDetails?.childrenDetails?.map((child, index) => {
                                            const num = index + 1;

                                            return (
                                                <>
                                                    <div className="sm:col-span-3">
                                                        <SelectInput
                                                            label={`Children ${num} / बच्चा ${num}`}
                                                            placeholder={`Children ${num}`}
                                                            options={["Girl", "Boy"]}
                                                            required="required"
                                                            {...register(`children${num}`, {
                                                                value: child?.Children,
                                                            })}
                                                            onChange={(e) => handleChange(`children${num}`, e)}
                                                        />
                                                        {errors[`children${num}`] && (
                                                            <p id="username-error" className="text-red-500 text-xs mt-1">
                                                                {errors[`children${num}`].message}
                                                            </p>
                                                        )}
                                                    </div>
                                                    {/* Children */}

                                                    {/* Children Age */}
                                                    <div className="sm:col-span-3">
                                                        <Input
                                                            label={`Children ${num} Age / बच्चा ${num} आयु`}
                                                            placeholder={`Children ${num} Age`}
                                                            type="text"
                                                            required="required"
                                                            {...register(`children${num}Age`, {
                                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                                value: `${child?.ChildrenAge}`,

                                                                minLength: {
                                                                    value: 1,
                                                                    message: "कृपया कम से कम 1 अंक भरें",
                                                                },
                                                                maxLength: {
                                                                    value: 2,
                                                                    message: "कृपया 2 अंक से अधिक न भरें",
                                                                },
                                                                pattern: {
                                                                    value: /^\d{1,2}$/,
                                                                    message: "कृपया मान्य आयु दर्ज करें",
                                                                },
                                                            })}
                                                            onChange={(e) => handleChange(`children${num}Age`, e)}
                                                        />
                                                        {errors[`children${num}Age`] && (
                                                            <p id="username-error" className="text-red-500 text-xs mt-1">
                                                                {errors[`children${num}Age`].message}
                                                            </p>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        })
                                        : <>
                                            <div className="sm:col-span-3">
                                                <SelectInput
                                                    label="Children 1 / बच्चा 1"
                                                    placeholder="Children 1"
                                                    options={["Girl", "Boy"]}
                                                    required="required"
                                                    {...register("children1", {

                                                    })}
                                                    onChange={(e) => handleChange("children1", e)}
                                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                                />
                                                {errors.children1 && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.children1.message}</p>}
                                            </div>


                                            <div className="sm:col-span-3">
                                                <Input
                                                    label="Children 1 Age / बच्चा 1 आयु"
                                                    placeholder="Children 1 Age"
                                                    type="text"
                                                    required="required"
                                                    {...register("children1Age", {
                                                        required: "इस फ़ील्ड को भरना ज़रूरी है",

                                                        minLength: {
                                                            value: 1,
                                                            message: "कृपया कम से कम 1 अंक भरें"
                                                        },
                                                        maxLength: {
                                                            value: 2,
                                                            message: "कृपया 2 अंक से अधिक न भरें"
                                                        },
                                                        pattern: {
                                                            value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                            message: "कृपया मान्य आयु दर्ज करें"
                                                        }
                                                    })}
                                                    onChange={(e) => handleChange("children1Age", e)}
                                                />
                                                {errors.children1Age && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.children1Age.message}</p>}
                                            </div>

                                            <div className="sm:col-span-3">
                                                <SelectInput
                                                    label="Children 2 / बच्चा 2"
                                                    placeholder="Children 2"
                                                    options={["Girl", "Boy"]}
                                                    required="required"
                                                    {...register("children2")}
                                                    onChange={(e) => handleChange("children2", e)}
                                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                                />
                                                {errors.children2 && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.children2.message}</p>}
                                            </div>

                                            {/* Children2 Age */}
                                            <div className="sm:col-span-3">
                                                <Input
                                                    label="Children 2 Age / बच्चा 2 आयु"
                                                    placeholder="Children 2 Age"
                                                    type="text"
                                                    required="required"
                                                    {...register("children2Age", {
                                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                        minLength: {
                                                            value: 1,
                                                            message: "कृपया कम से कम 1 अंक भरें"
                                                        },
                                                        maxLength: {
                                                            value: 2,
                                                            message: "कृपया 2 अंक से अधिक न भरें"
                                                        },
                                                        pattern: {
                                                            value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                            message: "कृपया मान्य आयु दर्ज करें"
                                                        }
                                                    })}
                                                    onChange={(e) => handleChange("children2Age", e)}
                                                />
                                                {errors.children2Age && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.children2Age.message}</p>}
                                            </div>


                                            <div className="sm:col-span-3">
                                                <SelectInput
                                                    label="Children 3 / बच्चा 3"
                                                    placeholder="Children 3"
                                                    options={["Girl", "Boy"]}
                                                    required="required"
                                                    {...register("children3")}
                                                    onChange={(e) => handleChange("children3", e)}
                                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                                />
                                                {errors.children3 && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.children3.message}</p>}
                                            </div>

                                            {/* Children3 Age */}
                                            <div className="sm:col-span-3">
                                                <Input
                                                    label="Children 3 Age / बच्चा 3 आयु"
                                                    placeholder="Children 3 Age"
                                                    type="text"
                                                    required="required"
                                                    {...register("children3Age", {
                                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                        minLength: {
                                                            value: 1,
                                                            message: "कृपया कम से कम 1 अंक भरें"
                                                        },
                                                        maxLength: {
                                                            value: 2,
                                                            message: "कृपया 2 अंक से अधिक न भरें"
                                                        },
                                                        pattern: {
                                                            value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                            message: "कृपया मान्य आयु दर्ज करें"
                                                        }
                                                    })}
                                                    onChange={(e) => handleChange("children3Age", e)}
                                                />
                                                {errors.children3Age && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.children3Age.message}</p>}
                                            </div></>
                                    }

                                </span>}
                                {/* ************************************************************************                              */}

                                <div className="col-span-full">
                                    <Textarea
                                        label="Permanent Address / स्थाई पता"
                                        placeholder="Permanent Address"
                                        type="text"
                                        required="required"

                                        {...register("permanentAddress", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.permanentAddress}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 20 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("permanentAddress", e)}
                                    />
                                    {errors.permanentAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.permanentAddress.message}</p>}

                                </div>


                                <div className="sm:col-span-3">
                                    <CityDropDown
                                        options={cityOptions}
                                        setCity={setCityOptions}
                                        search={'city'}
                                        id={'permanentCity'}
                                        required="required"
                                        setOptions={setPermanentCity}
                                        label={"Permanent City / स्थायी शहर"}
                                        className={' w-full  h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                        style={' text-black font-bold'}
                                        alredySetCity={permanentCity}
                                    />
                                </div>
                                {/* pincode */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Pincode / पिनकोड"
                                        placeholder="पिनकोड"
                                        type="text"
                                        required="required"
                                        {...register("permanentPincode", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.permanentPincode}`,

                                            minLength: {
                                                value: 6,
                                                message: "कृपया कम से कम 6 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 6,
                                                message: "कृपया 6 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[0-9]\d{5}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य पिनकोड नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("permanentPincode", e)}
                                    />
                                    {errors.permanentPincode && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.permanentPincode.message}</p>}
                                </div>


                                <div className="col-span-full">
                                    <Textarea
                                        label="Current address / वर्त्तमान पता"
                                        placeholder="Current address "
                                        type="text"
                                        required="required"
                                        {...register("currentAddress", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.currentAddress}`,

                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 100 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("currentAddress", e)}
                                    />
                                    {errors.currentAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentAddress.message}</p>}

                                </div>
                                {/* currentAddress city */}

                                <div className="sm:col-span-3">
                                    <CityDropDown
                                        options={cityOptions}
                                        setCity={setCityOptions}
                                        search={'city'}
                                        id={'currentCity'}
                                        setOptions={setCurrentCity}
                                        required="required"
                                        label={"Current city / वर्तमान शहर"}
                                        className={' w-full h-9 px-4 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                        style={' text-black font-bold'}
                                        alredySetCity={currentCity}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <Input
                                        label="Pincode / पिनकोड"
                                        placeholder="पिनकोड"
                                        type="text"
                                        required="required"
                                        {...register("currentPincode", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.currentPincode}`,
                                            minLength: {
                                                value: 6,
                                                message: "कृपया कम से कम 6 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 6,
                                                message: "कृपया 6 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[0-9]\d{5}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य पिनकोड नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("currentPincode", e)}
                                    />
                                    {errors.currentPincode && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.currentPincode.message}</p>}
                                </div>

                            </div>
                            <FormHeading heading={'kundli details / कुंडली  जानकारी '} />
                            {/* Date of birth/time------compulsaryExact
                             age per date of birth----Auto calculate by
                              date of 
                              birthPlace of birth------compulsary
                              Rashi by birth------compulsary
                              Rashi by date of birth------Not 
                              compulsary
                              Mangalik - yes or no------compulsary */}
                            {/* Education qualification=-------500
                               charactersOccupation details :
                               Occupation - self business ,family business ,private job ,govt. Job ,other------compulsary
                               Company Name - designation city
                                average salary per month------Not compulsary */}
                            {/* permanentAddress */}
                            <div className=" border-gray-900/10 pb-2">
                                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                                    {/* Date of birth/time */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="birth Date Time / जनम दिनांक - टाइम"
                                            placeholder="Date of birth/time"
                                            required="required"
                                            type="datetime-local"
                                            defaultValue={
                                                formData?.kundliDetails?.birthDateTime
                                                    ? new Date(formData.kundliDetails.birthDateTime).toISOString().slice(0, 16)
                                                    : ""
                                            }
                                            {...register("birthDateTime", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            })}
                                            onChange={(e) => calculateAgeFromDateTime(e)}
                                        />
                                        {errors.birthDateTime && (
                                            <p id="dharmashala-error" className="text-red-500 text-xs mt-1">
                                                {errors.birthDateTime.message}
                                            </p>
                                        )}
                                    </div>


                                    {/* Age */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="Age / उम्र"
                                            placeholder="Age"
                                            required="required"
                                            type="text"
                                            value={age}
                                            disabled={true}
                                        />
                                        {/* {errors.age && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.age.message}</p>} */}
                                    </div>

                                    {/* birthPlace of birth */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="birth Place / जन्म स्थान"
                                            placeholder="birth Place"
                                            type="text"
                                            required="required"
                                            {...register("birthPlace", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.kundliDetails?.birthPlace}`,
                                                minLength: {
                                                    value: 3,
                                                    message: "कृपया कम से कम 3 अक्षर भरें"
                                                },
                                                maxLength: {
                                                    value: 40,
                                                    message: "कृपया 40 अक्षरों से कम भरें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("birthPlace", e)}
                                        />
                                        {errors.birthPlace && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.birthPlace.message}</p>}
                                    </div>

                                    {/* Rashi by date of birth */}
                                    {/* <div className="sm:col-span-3">
                                        <SelectInput
                                            label="Rashi by date of birth / राशि - जन्म तारीख"
                                            placeholder="Rashi by date of birth"
                                            options={rashis}
                                            // required="required"
                                            {...register("rashiByDateOfBirth", {
                                                value: `${formData?.kundliDetails?.rashiByDateOfBirth}`,
                                            })}
                                            onChange={(e) => handleChange("rashiByDateOfBirth", e)}
                                        // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                        />
                                        {errors.rashiByDateOfBirth && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.rashiByDateOfBirth.message}</p>}
                                    </div> */}

                                    {/* Rashi by birth */}
                                    {/* <div className="sm:col-span-3">
                                        <SelectInput
                                            label="Rashi by birth / राशि - जन्म"
                                            placeholder="Rashi by birth"
                                            options={rashis}

                                            {...register("rashi", {

                                                value: `${formData?.kundliDetails?.rashi}`,
                                            })}
                                            onChange={(e) => handleChange("rashi", e)}
                                        // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                        />
                                        {errors.rashi && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.rashi.message}</p>}
                                    </div> */}
                                    {/* Mangalik */}
                                    <div className="sm:col-span-3">
                                        <SelectInput
                                            label="Mangalik / मांगलिक"
                                            placeholder="Mangalik"
                                            options={['हाँ', 'नहीं', 'पतानहीं']}
                                            required="required"
                                            {...register("mangalik", {
                                                value: `${formData?.kundliDetails?.mangalik}`,
                                            })}
                                            onChange={(e) => handleChange("mangalik", e)}
                                        // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                        />
                                        {errors.mangalik && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mangalik.message}</p>}
                                    </div>
                                </div>
                            </div>

                            <FormHeading heading={'Education qualification / शैक्षणिक योग्यता'} />
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                                {/* Education qualification */}
                                <div className="col-span-full  ">
                                    <Textarea
                                        label="Education qualification / शैक्षणिक योग्यता"
                                        placeholder="Education qualification "
                                        required="required"
                                        type="text"
                                        {...register("educationQualification", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.educationQualification}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 20 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 500,
                                                message: "कृपया 500 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("educationQualification", e)}
                                    />
                                    {errors.educationQualification && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.educationQualification.message}</p>}

                                </div>
                            </div>

                            <FormHeading heading={'Occupation details / व्यवसाय विवरण'} />
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                                {/* Occupation */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Occupation / व्यवसाय"
                                        placeholder="Occupation"
                                        options={occupations}
                                        required="required"
                                        {...register("occupation", {
                                            value: `${formData?.occupationDetails?.occupation}`,

                                        })}
                                        onChange={(e) => handleChange("occupation", e)}
                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                    />
                                    {errors.occupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.occupation.message}</p>}
                                </div>

                                {/* Company Name */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Company Name / कंपनी का नाम"
                                        placeholder="Company Name"
                                        type="text"
                                        {...register("companyName", {
                                            value: `${formData?.occupationDetails?.companyName}`,
                                            // value: `${formData?.name}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("companyName", e)}
                                    />
                                    {errors.companyName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.companyName.message}</p>}
                                </div>

                                {/* designation */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="designation / कंपनी मैं पद"
                                        placeholder="designation"
                                        type="text"
                                        {...register("designation", {
                                            value: `${formData?.occupationDetails?.designation}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("designation", e)}
                                    />
                                    {errors.designation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.designation.message}</p>}
                                </div>

                                {/* city */}
                                {/* <div className="sm:col-span-3">
                                    <CityDropDown
                                        options={cityOptions}
                                        setCity={setCityOptions}
                                        search={'city'}
                                        id={'occupationCity'}
                                        // required="required"
                                        setOptions={setOccupationCity}
                                        label={"occupations city / कंपनी का शहर"}
                                        className={' w-full  h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                        style={' text-black font-bold'}
                                        alredySetCity={occupationCity}
                                    />
                                </div> */}

                                {/* occupationCityText */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="occupations city / कंपनी का शहर"
                                        placeholder="Occupation City"
                                        type="text"
                                        {...register("occupationCityText", {
                                            value: `${formData?.occupationDetails?.occupationCityText || ""}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("occupationCityText", e)}
                                    />
                                    {errors.occupationCityText && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.occupationCityText.message}</p>}
                                </div>


                                {/* average salary per month */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="average salary per month / महीने की सैलरी"
                                        placeholder="average salary per month"
                                        type="text"
                                        {...register("averageSalaryPerMonth", {
                                            value: `${formData?.occupationDetails?.averageSalaryPerMonth || ""}`,
                                            minLength: {
                                                value: 1,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /\d/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य सैलरी दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("averageSalaryPerMonth", e)}
                                    />
                                    {errors.averageSalaryPerMonth && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.averageSalaryPerMonth.message}</p>}
                                </div>
                            </div>

                            <FormHeading heading={'Contact Details / संपर्क  जानकारी'} />

                            {/* Contact Details :
                            Mobile number------compulsary
                            WhatsApp number------compulsary
                            Facebook id------Not compulsary
                            LinkedIn id------Not compulsary
                            Instagram ID------Not compulsary
                            email ID--------not compulsary
                            Hobbies------Not compulsary */}
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                                {/* mobile number */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Mobile no / मोबाइल नंबर      (will be login number)"
                                        placeholder="Mobile no"
                                        type="number"
                                        required="required"
                                        disabled={(formData?.contactDetails?.mobileNo && authData?.user?.userType !== 1)}
                                        {...register("mobileNo", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.contactDetails?.mobileNo}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                            }
                                        })}
                                        // onChange={(e) => handleChange("mobileNo", e)}
                                        onChange={(e) => handleRegisterMobileNoChange(e)}
                                    />
                                    {errors.mobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.mobileNo.message}</p>}
                                    {isMoblieNumberAlreadyExist && <span className=' animate-ping  w-100 h-100'>🔃 checking</span>}
                                    {mobileNumberCheckLoading && <small className=' text-green-700   w-100 h-100'> ✔ Valid Mobile Number</small>}                                </div>

                                {/* whatsappNo */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Whatsapp No / व्हाट्सएप नंबर"
                                        placeholder="Whatsapp No"
                                        type="number"
                                        required="required"
                                        {...register("whatsappNo", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.contactDetails?.whatsappNo}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("whatsappNo", e)}
                                    />
                                    {errors.whatsappNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.whatsappNo.message}</p>}
                                </div>

                                {/* Facebook id */}
                                {/* <div className="sm:col-span-3">
                                    <Input
                                        label="Facebook id / फेसबुक की आईडी"
                                        placeholder="Facebook id"
                                        type="text"
                                        {...register("facebookId", {
                                            value: `${formData?.contactDetails?.facebookId}`,
                                            pattern: {
                                                value: /^https:\/\/(www\.)?facebook\.com\/([a-zA-Z0-9._-]+)\/?$/,
                                                message: "Please enter a valid Facebook profile URL starting with 'https://'"
                                            }
                                        })}
                                        onChange={(e) => handleChange("facebookId", e)}
                                    />
                                    {errors.facebookId && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.facebookId.message}</p>}
                                </div> */}

                                {/* LinkedIn id */}
                                {/* <div className="sm:col-span-3">
                                    <Input
                                        label="LinkedIn id / लिंकडइन की आईडी"
                                        placeholder="LinkedIn id"
                                        type="text"
                                        {...register("linkedInId", {
                                            value: `${formData?.contactDetails?.linkedInId}`,
                                            minLength: {
                                                value: 5,
                                                message: "Please enter at least 5 characters"
                                            },
                                            pattern: {
                                                value: /^(https?:\/\/)?([\w\-]+\.)?linkedin\.com\/(in|pub)\/[A-Za-z0-9_-]+\/?$/,
                                                message: "Please enter a valid LinkedIn ID"
                                            }
                                        })}
                                        onChange={(e) => handleChange("linkedInId", e)}
                                    />
                                    {errors.linkedInId && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.linkedInId.message}</p>}
                                </div> */}

                                {/* Instagram id */}
                                {/* <div className="sm:col-span-3">
                                    <Input
                                        label="Instagram id / इंस्टाग्राम की आईडी"
                                        placeholder="Instagram id"
                                        type="text"
                                        {...register("instagramId", {
                                            value: `${formData?.contactDetails?.instagramId}`,
                                            pattern: {
                                                value: /^https:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9._-]+)\/?$/,
                                                message: "Please enter a valid Instagram profile URL starting with 'https://'"
                                            }
                                        })}
                                        onChange={(e) => handleChange("instagramId", e)}
                                    />
                                    {errors.instagramId && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.instagramId.message}</p>}
                                </div> */}
                                {/* email */}
                                {/* <div className="sm:col-span-3">
                                    <Input
                                        label="Email id / ईमेल आईडी"
                                        placeholder="Email id"
                                        type="email" // Change type to "email" for email validation
                                        // required="required"
                                        {...register("emailId", {

                                            value: `${formData?.contactDetails?.emailId}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            // maxLength: {
                                            //     value: 20,
                                            //     message: "कृपया 20 अक्षरों से कम भरें"
                                            // },
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regular expression for email validation
                                                message: "कृपया मान्य ईमेल पता दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("emailId", e)}
                                    />
                                    {errors.emailId && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.emailId.message}</p>}
                                </div> */}
                            </div>

                        </div>
                        <FormHeading heading={'Family Detail / पारिवारिक विवरण'} />
                        {/* Family details */}
                        {/* -father's name ,
                        occupation,
                        mobile number,
                        photo , ---compulsary
                        Mother name,
                        occupation,
                        mobile no,
                        photo---compulsary
                        brother -  count, married or unmarried---compulsary
                        sister - count, married or unmarried---compulsary
                        Residing with family - yes or no---compulsary
                        Current family type - joint , nuclear , independent,single---compulsary
                        interested in family type- joint, single , independent---compulsary */}

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            {/* fatherName */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Father's Name / पिताजी नाम"
                                    placeholder=" पिताजी का नाम"
                                    type="text"

                                    required="required"
                                    {...register("fatherName", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.fatherName}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "कृपया 100 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("fatherName", e)}
                                />
                                {errors.fatherName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherName.message}</p>}
                            </div>

                            {/* father Occupation */}
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="Father Occupation / पिता का व्यवसाय"
                                    placeholder="Father Occupation"
                                    options={occupations}
                                    // required="required"
                                    {...register("fatherOccupation", {
                                        value: `${formData?.familyDetail?.fatherOccupation}`,
                                    })}
                                    onChange={(e) => handleChange("fatherOccupation", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.fatherOccupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherOccupation.message}</p>}
                            </div>
                            {/* father number */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Father Mobile no / पिता का मोबाइल नंबर"
                                    placeholder="Father Mobile no"
                                    type="number"
                                    // required="required"
                                    {...register("fatherMobileNo", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.fatherMobileNo}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 10 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "कृपया 10 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("fatherMobileNo", e)}
                                />
                                {errors.fatherMobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.fatherMobileNo.message}</p>}
                            </div>

                            {/* Father Photo */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Father Photo / पिता का फोटो"
                                    placeholder="Father Photo"
                                    type="file"
                                    required="required"
                                    onChange={(e) => handleFileChange("fatherPhoto", e)}

                                />
                                {errors.fatherPhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.fatherPhoto.message}</p>}
                            </div> */}

                            {/* mother Name */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Mother Name / माँ का नाम"
                                    placeholder="Mother Name"
                                    type="text"
                                    required="required"
                                    {...register("motherName", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.motherName}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "कृपया 100 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("motherName", e)}
                                />
                                {errors.motherName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.motherName.message}</p>}
                            </div>

                            {/* Mother Occupation */}
                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="Mother Occupation / माँ का व्यवसाय"
                                    placeholder="Mother Occupation"
                                    options={occupations}
                                    // required="required"
                                    {...register("motherOccupation", {
                                        value: `${formData?.familyDetail?.motherOccupation}`,
                                    })}
                                    onChange={(e) => handleChange("motherOccupation", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.motherOccupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.motherOccupation.message}</p>}
                            </div> */}
                            {/* motherMobileNo number */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Mother Mobile no / माँ का मोबाइल नंबर"
                                    placeholder="Mother Mobile no"
                                    type="number"
                                    // required="required"
                                    {...register("motherMobileNo", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.motherMobileNo}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 10 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "कृपया 10 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("motherMobileNo", e)}
                                />
                                {errors.motherMobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.motherMobileNo.message}</p>}
                            </div> */}

                            {/* Mother Photo */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Mother Photo / माँ का फोटो"
                                    placeholder="Mother Photo"
                                    type="file"
                                    required="required"
                                    onChange={(e) => handleFileChange("motherPhoto", e)}
                                />
                                {errors.fatherPhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.fatherPhoto.message}</p>}
                            </div> */}

                            {/* brother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Brother / भाई"
                                    placeholder="Brother"
                                    type="text"
                                    required="required"
                                    {...register("brother", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.brother}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }


                                    })}
                                    onChange={(e) => handleChange("brother", e)}
                                />
                                {errors.brother && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.brother.message}</p>}
                            </div>
                            {/* marriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Married Brother / शादीशुदा भाई"
                                    placeholder="Married Brother"
                                    type="text"
                                    required="required"
                                    {...register("marriedBrother", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.marriedBrother}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("marriedBrother", e)}
                                />
                                {errors.marriedBrother && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.marriedBrother.message}</p>}
                            </div>

                            {/* unmarriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Unmarried Brother / अविवाहित भाई"
                                    placeholder="Unmarried Brother"
                                    type="text"
                                    required="required"
                                    {...register("unmarriedBrother", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.unmarriedBrother}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("unmarriedBrother", e)}
                                />
                                {errors.unmarriedBrother && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.unmarriedBrother.message}</p>}
                            </div>



                            {/* sister */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Sister / बहन"
                                    placeholder="Sister"
                                    type="text"
                                    required="required"
                                    {...register("sisters", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.sisters}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }


                                    })}
                                    onChange={(e) => handleChange("sisters", e)}
                                />
                                {errors.sisters && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.sisters.message}</p>}
                            </div>
                            {/* marriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Married Sister / विवाहित बहन"
                                    placeholder="Married Sister"
                                    type="text"
                                    required="required"
                                    {...register("marriedSister", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.marriedSister}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("marriedSister", e)}
                                />
                                {errors.marriedSister && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.marriedSister.message}</p>}
                            </div>

                            {/* unmarriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Unmarried Sister / अविवाहित बहन"
                                    placeholder="Unmarried Sister"
                                    type="text"
                                    required="required"
                                    {...register("unmarriedSister", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.unmarriedSister}`,

                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("unmarriedSister", e)}
                                />
                                {errors.unmarriedSister && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.unmarriedSister.message}</p>}
                            </div>

                            {/* Residing with family */}

                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="Residing with family / परिवार के साथ निवास कर रहे हैं"
                                    placeholder="Residing with family"
                                    options={['YES', 'NO']}
                                    required="required"
                                    {...register("residingWithFamily", {
                                        value: `${formData?.familyDetail?.residingWithFamily}`,
                                    })}
                                    onChange={(e) => handleChange("residingWithFamily", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.residingWithFamily && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.residingWithFamily.message}</p>}
                            </div> */}

                            {/* Current family type */}
                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="Current family type / वर्तमान परिवार प्रकार"
                                    placeholder="Current family type"
                                    options={['Joint', 'Nuclear', 'Independent', 'Single']}
                                    required="required"
                                    {...register("currentFamilyType", {
                                        value: `${formData?.familyDetail?.currentFamilyType}`,
                                    })}
                                    onChange={(e) => handleChange("currentFamilyType", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.currentFamilyType && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentFamilyType.message}</p>}
                            </div> */}

                            {/* interested in family type */}
                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="interested in family type / परिवार के प्रकार में रुचि"
                                    placeholder="interested in family type"
                                    options={['Joint', 'Nuclear', 'Independent', 'Single']}
                                    required="required"
                                    {...register("interestedInFamilyType", {
                                        value: `${formData?.familyDetail?.interestedInFamilyType}`,
                                    })}
                                    onChange={(e) => handleChange("interestedInFamilyType", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.interestedInFamilyType && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.interestedInFamilyType.message}</p>}
                            </div> */}
                        </div>

                        <FormHeading heading={'Mama details / मामा की जानकारी '} />
                        {/* 
                        Mama details -
                        mama name-----compulsary
                         occupation---compulsary
                         photo---compulsary
                         City---compulsary
                         gotra---compulsary */}

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            {/*Mama name */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Mama name / मामा का नाम"
                                    placeholder="Mama name"
                                    type="text"
                                    required="required"

                                    {...register("mamaName", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.mamaDetails?.mamaName}`,


                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 40,
                                            message: "कृपया 40 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("mamaName", e)}
                                />
                                {errors.mamaName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mamaName.message}</p>}
                            </div>
                            {/* Mama Occupation */}
                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="Mama Occupation / मामा का व्यवसाय"
                                    placeholder="Mama Occupation"
                                    options={occupations}
                                    required="required"
                                    {...register("mamaOccupation", {
                                        value: `${formData?.mamaDetails?.mamaOccupation}`,
                                    })}
                                    onChange={(e) => handleChange("mamaOccupation", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.mamaOccupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mamaOccupation.message}</p>}
                            </div> */}

                            {/* Mobile no / मोबाइल नंबर */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Mobile no / मोबाइल नंबर"
                                    placeholder="Mobile no"
                                    type="number"
                                    required="required"
                                    {...register("mamaMobileNo", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.mamaDetails?.mamaMobileNo}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 10 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "कृपया 10 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("mamaMobileNo", e)}
                                />
                                {errors.mamaMobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.mamaMobileNo.message}</p>}
                            </div> */}

                            {/* mama city */}
                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="Mama city / मामा का शहर"
                                    placeholder="Mama city"
                                    options={["indor", "bhopal"]}
                                    required="required"
                                    {...register("mamaCity")}
                                    onChange={(e) => handleChange("mamaCity", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.mamaCity && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mamaCity.message}</p>}
                            </div> */}

                            {/* <div className="sm:col-span-3">
                                <CityDropDown
                                    options={cityOptions}
                                    setCity={setCityOptions}
                                    search={'city'}
                                    id={'mamaCity'}
                                    required="required"
                                    setOptions={setMamaCity}
                                    label={"Mama city / मामा का शहर"}
                                    className={' w-full  h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                    style={' text-black font-bold'}
                                    alredySetCity={mamaCity}
                                />
                            </div> */}

                            {/*Mama gotra */}
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="Mama gotra / मामा की गोत्र"
                                    placeholder="Mama gotra"
                                    options={gotra}
                                    required="required"
                                    {...register("mamaGotra", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.mamaDetails?.mamaGotra}`,
                                    }
                                    )}
                                    onChange={(e) => handleChange("mamaGotra", e)}
                                />
                                {errors.mamaGotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mamaGotra.message}</p>}
                            </div>
                            {/* Mama Photo */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Mama Photo / मामा का फोटो "
                                    placeholder="Mama Photo"
                                    type="file"
                                    required="required"
                                    onChange={(e) => handleFileChange("mamaPhoto", e)}
                                />
                                {errors.fatherPhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.fatherPhoto.message}</p>}
                            </div> */}

                        </div>

                        {/* <FamilyDetails members={formData?.memberFamilyDetails} setValue={setValue} /> */}
                        {/* <FormHeading heading={'Others Details / अन्य विवरण'} /> */}
                        {/* Achievements / awards------2000 characters------not compulsary */}
                        {/* <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5"> */}
                        {/* Achievements / awards */}
                        {/* <div className="col-span-full  ">
                                <Textarea
                                    label="Achievements / awards / उपलब्धियाँ/पुरस्कार"
                                    placeholder="Achievements / awards "
                                    type="text"
                                    {...register("achievementsAwards", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.othersDetails?.achievementsAwards}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 20 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 2000,
                                            message: "कृपया 2000 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("achievementsAwards", e)}
                                />
                                {errors.achievementsAwards && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.achievementsAwards.message}</p>}

                            </div> */}

                        {/* Expectation from relation */}
                        {/* <div className="col-span-full  ">
                                <Textarea
                                    label="Expectation from relation / रिश्ते से उम्मीद"
                                    placeholder="Expectation from relation"
                                    type="text"
                                    required="required"
                                    {...register("expectationFromRelation", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.othersDetails?.expectationFromRelation}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 20 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 2000,
                                            message: "कृपया 2000 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("expectationFromRelation", e)}
                                />
                                {errors.expectationFromRelation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.expectationFromRelation.message}</p>}

                            </div> */}

                        {/* Hobbies */}
                        {/* <div className="sm:col-span-3">
                                <Input
                                    label="Hobbies / शौक"
                                    placeholder="Hobbies"
                                    type="text" // Change type to "email" for email validation
                                    // required="required"
                                    {...register("hobbies", {

                                        value: `${formData?.othersDetails?.hobbies}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 500,
                                            message: "कृपया कम से कम 500 अक्षर भरें"
                                        },


                                    })}
                                    onChange={(e) => handleChange("hobbies", e)}
                                />
                                {errors.hobbies && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.hobbies.message}</p>}
                            </div> */}
                        {/* </div> */}

                        <FormHeading heading={'Photo upload / फोटो अपलोड'} />
                        {/* 1 profile photo---compulsarypersonal
                        photo 1---compulsarypersonal
                        photo 2---compulsarypersonal
                        photo 3---compulsary1
                        Janam patrika---compulsary1
                        aadhar card---compulsary */}
                        {/* Father Photo */}
                        <AvatarEditorCom setEditedAvatar={setEditedAvatar} alreadySetAvatar={formData?.photos?.profilePhoto} />

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            {/* profile photo */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="profile photo / प्रोफ़ाइल फ़ोटो"
                                    placeholder="profile photo"
                                    type="file"
                                    required="required"
                                    onChange={(e) => handleFileChange("profilePhoto", e)}
                                />
                                {errors.profilePhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.profilePhoto.message}</p>}
                            </div> */}
                            {/* photo 1 */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Personal 1 / व्यक्तिगत 1"
                                    placeholder="Personal 1"
                                    type="file"
                                    required="required"

                                    onChange={(e) => handleFileChange("personalPhoto1", e)}
                                />
                                {errors.personalPhoto1 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.personalPhoto1.message}</p>}
                            </div>

                            {/* photo 2 */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Personal 2 / व्यक्तिगत 2"
                                    placeholder="Personal 2"
                                    type="file"
                                    onChange={(e) => handleFileChange("personalPhoto2", e)}
                                />
                                {errors.personalPhoto2 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.personalPhoto2.message}</p>}
                            </div>

                            {/* photo 3 */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Personal 3 / व्यक्तिगत 3"
                                    placeholder="Personal 3"
                                    type="file"
                                    required="required"

                                    onChange={(e) => handleFileChange("personalPhoto3", e)}
                                />
                                {errors.personalPhoto3 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.personalPhoto3.message}</p>}
                            </div> */}

                            {/* Janam patrika */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Janam patrika / जनम पत्रिका"
                                    placeholder="Janam patrika"
                                    type="file"

                                    onChange={(e) => handleFileChange("janamPatrika", e)}
                                />
                                {errors.janamPatrika && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.janamPatrika.message}</p>}
                            </div>

                            {/* Aadhar Card */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="Aadhar Card / आधार कार्ड"
                                    placeholder="Aadhar Card"
                                    type="file"
                                    required="required"
                                    onChange={(e) => handleFileChange("aadharCard", e)}
                                />
                                {errors.aadharCard && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.aadharCard.message}</p>}
                            </div> */}

                        </div>
                        <FormHeading heading={'Extra information / अतिरिक्त जानकारी'} />
                        {/* Extra information- */}
                        {/* Any health issues----1200 characters-------not compulsary
                        Special qualities------2000 characters----------not compulsary */}
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            {/* Any health issues */}
                            <div className="col-span-full  ">
                                <Textarea
                                    label="Any health issues / कोई भी स्वास्थ्य संबंधी समस्या"
                                    placeholder="Any health issues"
                                    type="text"
                                    {...register("anyHealthIssues", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.extraInformation?.anyHealthIssues}`,

                                        minLength: {
                                            value: 2,
                                            message: "कृपया कम से कम 2 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 1200,
                                            message: "कृपया 1200 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("anyHealthIssues", e)}
                                />
                                {errors.anyHealthIssues && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.anyHealthIssues.message}</p>}

                            </div>

                            {/* Special qualities */}
                            {/* <div className="col-span-full  ">
                                <Textarea
                                    label="Special qualities / विशिष्ट गुण"
                                    placeholder="Special qualities"
                                    type="text"
                                    {...register("specialQualities", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.extraInformation?.specialQualities}`,
                                        minLength: {
                                            value: 2,
                                            message: "कृपया कम से कम 2 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 2000,
                                            message: "कृपया 2000 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("specialQualities", e)}
                                />
                                {errors.specialQualities && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.specialQualities.message}</p>}

                            </div> */}
                        </div>

                        <FormHeading heading={' Remarks/Notes / टिप्पणियाँ'} />
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            <div className="col-span-full  ">
                                <Textarea
                                    label="Remarks/Notes / टिप्पणियाँ"
                                    placeholder="Remarks/Notes"
                                    type="text"
                                    {...register("remarksNotes", {

                                        value: `${formData?.remarksNotes}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 20 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 1200,
                                            message: "कृपया 1200 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("remarksNotes", e)}
                                />
                                {errors.remarksNotes && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.remarksNotes.message}</p>}

                            </div>
                        </div>
                        {!authData.isLogin && <div className="mt-6 space-y-6 w-full text-center flex justify-center">
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        // checked={isChecked}
                                        onClick={(e) => handelTermsAndConditions(e)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="comments" className="font-medium text-gray-900">
                                        I agree to the terms and conditions
                                    </label>
                                </div>
                            </div>
                        </div>}
                    </div>

                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <Link to={'/'} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </Link>
                        {/* <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> */}
                        <button
                            disabled={!termAndConditions && !authData.isLogin}
                            type="submit"
                            className={`rounded-md  ${!termAndConditions && !authData.isLogin ? 'bg-gray-400' : 'bg-indigo-600'} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

