import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderBoarder } from '../../Components';
import { useSelector } from 'react-redux';

export default function AdminHomePage() {
    const isAuthenticated = useSelector(state => state?.auth);
    const isNotAdmin = isAuthenticated?.user?.membershipID !== 135;

    // 9827033232
    return (
        <>
            <HeaderBoarder />
            <div className='flex flex-wrap items-start px-5'>
                <div className='flex flex-wrap items-start sm:px-5 mb-5'>
                    {isNotAdmin && (
                        <>
                            <Link className='btn rounded-lg text-white bg-blue-800 p-5 font-bold text-center' to={'/admin/UserManagement'}>
                                UserManagement
                            </Link>
                            <Link className='btn rounded-lg text-white bg-blue-800 p-5 font-bold text-center' to={'/admin/LagaManagement'}>
                                LagaManagement
                            </Link>
                            <Link className='btn rounded-lg text-white bg-blue-800 p-5 font-bold text-center' to={'/admin/intrestReport'}>
                                intrestReport
                            </Link>
                            <Link className='btn rounded-lg text-white bg-blue-800 p-5 font-bold text-center' to={'/admin/samajReport'}>
                                samajReport
                            </Link>
                        </>
                    )}
                    <Link className='btn rounded-lg text-white bg-blue-800 p-5 font-bold text-center' to={'/admin/suggessions'}>
                        Suggessetion
                    </Link>

                </div>
            </div>
        </>
    );
}
